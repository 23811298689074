/*
 * @Author: 智客云网络科技
 * @Date: 2021-04-22 00:43:45 +0800
 * @LastEditors: 阡陌OvO
 * @LastEditTime: 2022-03-27 18:32:42
 * @Description: 路由
 * @FilePath: \src\router\routes.js
 */

const routes = [
  {
    path: "/",
    redirect: "/Home",
  },
  {
    id: "a6s28w1g74",
    title: "Main",
    name: "Main",
    path: "/",
    component: () => import("@/components/main/main.vue"),
    children: [],
  },
  {
    id: "login",
    title: "登录页",
    name: "Login",
    path: "/Login",
    component: () => import("@/views/Frame/login.vue"),
  },
  {
    id: "500",
    title: "500",
    name: "500",
    path: "/500",
    component: () => import("@/views/Frame/500.vue"),
  },
  {
    id: "403",
    title: "403",
    name: "403",
    path: "/403",
    component: () => import("@/views/Frame/403.vue"),
  },
];

export default routes;
