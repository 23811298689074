/*
 * @Author: 智客云网络科技
 * @Date: 2021-03-29 19:15:59 +0800
 * @LastEditors: 阡陌OvO
 * @LastEditTime: 2022-02-12 20:31:13
 * @Description: 请输入文件描述信息
 * @FilePath: \src\main.js
 */
import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import store from "./store";
import router from "./router";
import ElementUI from "element-ui";
import Utils from "@/utils";
import eIconPicker, { iconList } from "e-icon-picker";
import drag2anywhere from "@/command/drag2anywhere";

Vue.config.productionTip = false;

//状态管理
Vue.prototype.$state = store.state;

// element-ui
import "element-ui/lib/theme-chalk/index.css";
Vue.use(ElementUI);

// Utils
Vue.use(Utils);

//drag2anywhere
Vue.use(drag2anywhere);

// eIconPicker
import "e-icon-picker/lib/symbol.js"; //基本彩色图标库
import "e-icon-picker/lib/index.css"; //基本样式，包含基本图标
Vue.use(eIconPicker, {
  FontAwesome: false,
  ElementUI: true,
  eIcon: true, //自带的图标，来自阿里妈妈
  eIconSymbol: true, //是否开启彩色图标
  zIndex: 3100,
});
iconList.removeIcon(["el-icon-platform-eleme", "el-icon-eleme"]); //删除图标

// 全局默认样式
import "./global.css";

//禁止浏览器划选
// document.body.onselectstart = () => {
//   return false;
// };

// 打开vue
new Vue({
  store,
  router,
  render: (h) => h(App),
}).$mount("#app");
