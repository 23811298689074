<!--
 * @Author: 智客云网络科技
 * @Date: 2021-04-12 17:07:57 +0800
 * @LastEditors: 阡陌OvO
 * @LastEditTime: 2022-01-22 00:09:01
 * @Description: 请输入文件描述信息
 * @FilePath: \src\App.vue
-->
<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "App",
};
</script>
