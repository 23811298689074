/*
 * @Author: 智客云网络科技
 * @Date: 2021-04-13 20:38:32 +0800
 * @LastEditors: 阡陌OvO
 * @LastEditTime: 2022-01-21 23:59:28
 * @Description: 更改store中状态的唯一方法
 * @FilePath: \src\store\mutations.js
 */

const mutations = {
  //修改用户名
  SET_USERNAME: (state, username) => {
    state.user.username = username;
  },

  //修改昵称
  SET_NICKNAME: (state, nickname) => {
    state.user.nickname = nickname;
  },

  //修改头像
  SET_AVATAR: (state, avatar) => {
    state.user.avatar = avatar;
  },

  //修改昵称
  SET_TOKEN: (state, token) => {
    state.user.token = token;
  },

  //修改个签
  SET_SIGN: (state, sign) => {
    state.user.sign = sign;
  },

  //是否登录
  SET_IS_LOGIN: (state, islogin) => {
    state.user.isLogin = islogin;
  },

  //修改问候语
  SET_GREET: (state, greet) => {
    state.greet = greet;
  },

  //修改祝福语
  SET_BLESS: (state, greet) => {
    state.greet = greet;
  },

  //设置菜单列表
  SET_MENU_CONFIG: (state, menuList) => {
    state.menuList = menuList;
  },

  //设置路由表
  SET_ROUTES: (state, routes) => {
    state.routes = routes;
  },

  //切换主题
  SET_THEME_V: (state, themeV) => {
    state.themeV = themeV;
  },

  //初始化页面缓存
  INIT_OPEN_TAB: (state) => {
    state.cashViews = ["Home"];
  },

  //追加页面缓存
  UP_OPEN_TAB: (state, TabId) => {
    state.cashViews.push(TabId); //向数组末尾添加对象
  },

  //关闭页面缓存
  CLOSE_OPEN_TAB: (state, sss) => {
    let cv = state.cashViews;
    sss._vm.$utils.arrayDelete(cv, sss.route);
    state.cashViews = cv;
  },

  //切换首页打开状态
  SET_OPEN_HOME: (state, s) => {
    state.openHome = s;
  },

  //设置菜单和路由加载状态
  SET_MR_STATUS: (state, s) => {
    state.mrStatus = s;
  },
};

export default mutations;
