/*
 * @Author: 智客云网络科技
 * @Date: 2021-11-15 00:15:28
 * @LastEditors: 阡陌OvO
 * @LastEditTime: 2022-02-12 19:03:42
 * @Description: 封装请求
 * @FilePath: \src\api\index.js
 */

import request from "./request.js";
import qs from "qs";

export default {
  /**
   * get方法
   * @param {string} url 请求的url地址
   * @param {object} params 请求时携带的参数
   * @returns
   */
  get(url, params, showloading = true) {
    request.defaults["showloading"] = showloading;
    return request.get(url, { params });
  },

  /**
   * post方法
   * @param {string} url 请求的url地址
   * @param {object} params 请求时携带的参数
   * @returns
   */
  post(url, params, showloading = true) {
    request.defaults["showloading"] = showloading;
    //序列化数据
    params = qs.stringify(params, { indices: false });
    return request.post(url, params);
  },

  /**
   * put方法
   * @param {string} url 请求的url地址
   * @param {object} params 请求时携带的参数
   * @returns
   */
  put(url, params, showloading = true) {
    request.defaults["showloading"] = showloading;
    return request.put(url, params);
  },

  /**
   * delete方法
   * @param {string} url 请求的url地址
   * @param {object} params 请求时携带的参数
   * @returns
   */
  delete(url, params, showloading = true) {
    request.defaults["showloading"] = showloading;
    return request.delete(url, { params });
  },

  //fileUpload文件上传方法
  fileUpload(url, formData, showloading = true) {
    request.defaults["showloading"] = showloading;
    return request.post(url, formData, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  },
};
