/*
 * @Author: 智客云网络科技
 * @Date: 2021-04-12 17:07:57 +0800
 * @LastEditors: 阡陌OvO
 * @LastEditTime: 2022-01-27 01:24:17
 * @Description: 请输入文件描述信息
 * @FilePath: \src\store\index.js
 */
import Vue from "vue";
import Vuex from "vuex";
import getters from "./getters"; //导入模块
import actions from "./actions"; //导入模块
import mutations from "./mutations"; //导入模块

Vue.use(Vuex);

const state = {
  version: process.env.VUE_APP_APP_VERSION, //系统版本
  title: process.env.VUE_APP_DEFAULT_TITEL, //标题
  title_prefix: process.env.VUE_APP_TITEL_PREFIX, //标题前缀
  greet: "你好", //问候语
  bless: "祝你工作愉快!", //祝福语

  //user信息
  user: {
    username: "", //用户名
    nickname: "", //昵称
    avatar: "image/19700101/avatar-load-fail.png", //头像
    sign: "", //个签
    kor_name: "", //组织名称
    token: null, //Token值
    language: "zh_CN", //语言
    isLogin: false, //是否登录
  },

  routes: [], //路由表
  menuList: [], //菜单树
  mrStatus: false, //菜单和路由是否已加载
  cashViews: [], //已打开的页面名称 缓存页面用
  openHome: false, //已打开过主页
  themeV: "1", //主题样式
};

// 注册模块
const store = new Vuex.Store({
  state, //全局状态
  getters, //获取数据
  actions, //数据的异步操作
  mutations, //处理数据的唯一途径，state的改变或赋值只能在这里
});

//导出
export default store;
