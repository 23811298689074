/*
 * @Author: 智客云网络科技
 * @Date: 2021-04-20 16:35:00
 * @LastEditors: 阡陌OvO
 * @LastEditTime: 2022-03-27 23:00:50
 * @Description: 请输入文件描述信息
 * @FilePath: \src\router\index.js
 */
import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store"; //
import staticRoute from "./routes"; //静态路由
import { getMenuConfig } from "@/api/api"; //公共Api接口

//定义404路由
const Page404 = {
  id: "404",
  title: "404",
  name: "404",
  path: "*",
  component: () => import("@/views/Frame/404.vue"),
};

//注册VueRouter
Vue.use(VueRouter);

//创建VueRouter实例
const router = new VueRouter({
  mode: "history", //使用History模式 就是地址栏没有 # 的模式
  routes: staticRoute,
});

/**
 * 注册一个全局前置守卫
 * to:即将要进入的目标路由对象
 * from:当前导航正要离开的路由
 * next:一定要调用该方法来 resolve 这个钩子
 */
router.beforeEach((to, from, next) => {
  //读取本地缓存的Token
  //仅登录成功后才存在Token
  let Token = localStorage.getItem("token");

  if (to.path === "/Login") {
    //如果是访问登录界面，如果token存在，代表已登录过，跳转到主页

    if (Token) {
      next({ path: "/" });
    } else {
      //否则，跳转到登录页面
      next();
    }
  } else {
    if (!Token) {
      //用户Token不存在 代表未登录，跳转登录
      next({ path: "/Login" });
    } else {
      //写入用户信息
      _SetUserInfo();
      //添加动态菜单和路由后直接跳转
      _LoadMenuAndRoutes(to, from, next);
    }
  }
});

/**
 * 加载菜单和路由
 * @param {Router} to
 * @param {Router} from
 * @param {function} next
 * @returns
 */
function _LoadMenuAndRoutes(to, from, next) {
  //如果路由和菜单已加载则调用next();
  if (store.state.mrStatus) {
    next();
    return;
  }

  //优先从本地sessionStorage获取Mr配置
  let MrData = localStorage.getItem("MrData");
  //   MrData = null;

  if (MrData) {
    //转Json对象
    MrData = JSON.parse(MrData);

    //构建菜单和路由
    let buildMenuAndRoutes = _BuildMenuAndRoutes(MrData);
    if (!buildMenuAndRoutes) {
      console.log("Mr菜单构建失败!");
      return;
    }

    let MenuConfig = buildMenuAndRoutes[0]; //菜单配置
    let RoutesConfig = buildMenuAndRoutes[1]; //路由配置

    //根据静态路由配置可知router.options.routes[0]为根路由
    router.options.routes[0].children = RoutesConfig;

    //插入404路由
    router.options.routes = router.options.routes.concat([Page404]);

    //添加路由，让路由生效
    //会产生重复路由，控制台会有warn提示，但是不影响，vue-router会自动去重
    if (RoutesConfig.length > 0) {
      for (let i = 0; i < RoutesConfig.length; i++) {
        router.addRoute("Main", RoutesConfig[i]);
      }
    }
    router.addRoute(Page404);

    //菜单配置写入Store
    store.commit("SET_MENU_CONFIG", MenuConfig);

    //设置菜单和路由为已加载状态
    store.commit("SET_MR_STATUS", true);

    next({ ...to, replace: true });
  } else {
    //本地sessionStorage获取不到，从服务器端读取
    getMenuConfig()
      .then((res) => {
        //构建菜单和路由
        let buildMenuAndRoutes = _BuildMenuAndRoutes(res.data);
        if (!buildMenuAndRoutes) {
          console.log("Mr菜单构建失败!");
          return;
        }

        let MenuConfig = buildMenuAndRoutes[0]; //菜单配置
        let RoutesConfig = buildMenuAndRoutes[1]; //路由配置

        //添加路由
        router.options.routes[0].children = RoutesConfig;

        //插入404路由
        router.options.routes = router.options.routes.concat([Page404]);

        //添加路由，让路由生效
        //会产生重复路由，控制台会有warn提示，但是不影响，vue-router会自动去重
        if (RoutesConfig.length > 0) {
          for (let i = 0; i < RoutesConfig.length; i++) {
            router.addRoute("Main", RoutesConfig[i]);
          }
        }
        router.addRoute(Page404);

        //菜单配置写入Store
        store.commit("SET_MENU_CONFIG", MenuConfig);

        //设置菜单为已加载状态
        store.commit("SET_MR_STATUS", true);

        //菜单数据写入localStorage
        localStorage.setItem("MrData", JSON.stringify(res.data));

        next({ ...to, replace: true });
      })
      .catch((res) => {
        console.log(res);
      });
  }
}

/**
 * 构建菜单和路由配置
 * @param {array} MrConfig Mr配置
 */
function _BuildMenuAndRoutes(MrConfig) {
  //验证数据类型
  if (typeof MrConfig !== "object") {
    return false;
  }

  //检查列表是否为空
  if (MrConfig.length < 1) {
    //终止程序
    return false;
  }

  let BuildMenuCache = []; //菜单构建缓存
  let BuildRouteCache = []; //路由构建缓存
  for (let i = 0; i < MrConfig.length; i++) {
    let data = MrConfig[i]; //取出数据

    //定义菜单数据
    let menu = {
      id: data.menu_id, //菜单ID
      show: data.show, //菜单是否显示
      type: data.type, //菜单类型
      verify: data.verify, //菜单是否需要认证
      name: data.name, //菜单显示名称
      route: data.route, //菜单对应路由名
      icon: data.icon || "", //菜单图标
    };

    //定义路由数据
    if (data.type == "104011") {
      let routers = {
        name: data.route, //命名路由
        path: data.route, //路径
        component: () => import("@/views/" + data.uri + ".vue"), //命名视图组件
        // components: "", //命名视图组件
        // redirect: "", //重定向路径
        // alias: "", //别名
        // children: "", //嵌套路由
        // caseSensitive: false, //匹配规则是否大小写敏感?(默认 false)
        // pathToRegexpOptions: "", //编译正则的选项
        meta: {
          id: data.menu_id, //对应的菜单ID
          verify: data.verify, //认证标识
        }, //路由元信息 使用$route.meta.属性可以获取
      };
      BuildRouteCache.push(routers);
    }

    //判断是否有子级菜单路由
    if (data.childList && data.childList.length > 0) {
      let subConfig = _BuildMenuAndRoutes(data.childList);
      let subMenuConfig = subConfig[0];
      let subRouteConfig = subConfig[1];

      //写入子级菜单
      if (typeof subMenuConfig == "object" && subMenuConfig.length > 0) {
        menu.childList = subMenuConfig; //写入子级菜单
      }

      //子级路由写入平级
      if (typeof subRouteConfig == "object" && subRouteConfig.length > 0) {
        for (let subIndex = 0; subIndex < subRouteConfig.length; subIndex++) {
          const subRC = subRouteConfig[subIndex];
          BuildRouteCache.push(subRC);
        }
      }
    }

    BuildMenuCache.push(menu); //插入菜单配置
  }
  return [BuildMenuCache, BuildRouteCache];
}

/**
 * 向Vuex内写入用户信息
 */
function _SetUserInfo() {
  //设置用户信息
  var userinfo = localStorage.getItem("userinfo");
  var token = localStorage.getItem("token");

  if (userinfo && token) {
    var uf = JSON.parse(userinfo);
    store.commit("SET_USERNAME", uf.username); //用户名
    store.commit("SET_NICKNAME", uf.nickname); //用户昵称
    store.commit("SET_SIGN", uf.sign); //个签
    store.commit("SET_AVATAR", uf.avatar); //头像
    store.commit("SET_IS_LOGIN", true); //写入登录态
    store.commit("SET_TOKEN", token); //token
  }
}

// 解决编程式路由往同一地址跳转时会报错的情况
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

export default router;
