/*
 * @Author: 智客云网络科技
 * @Date: 2021-04-13 20:38:17 +0800
 * @LastEditors: 阡陌OvO
 * @LastEditTime: 2022-01-21 23:59:17
 * @Description: store中state的派生状态
 * @FilePath: \src\store\getters.js
 */

const getters = {
  GET_VERSION: (state) => {
    return state.version;
  },

  GET_AVATAR: (state) => {
    return state.avatar;
  },
};

export default getters;
