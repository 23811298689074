/*
 * @Author: 智客云网络科技
 * @Date: 2021-04-01 01:38:47 +0800
 * @LastEditors: 阡陌OvO
 * @LastEditTime: 2022-02-12 19:02:02
 * @Description: 公共API接口
 * @FilePath: \src\api\api.js
 */

// 请求方法封装
import http from "./index";

//本地域账号登录
export const login = (params) => http.post("/v1/Account/Login", params);

//本地域账号退出登录
export const logout = () => http.post("/v1/Account/Logout");

//获取菜单配置
export const getMenuConfig = () => http.get("/v1/Menu/GetMenuConfig");

// 上传图片文件
export const uploadImage = (params) =>
  http.fileUpload("/v1/File/UploadImage", params);

// 上传文件
export const uploadFile = (params) =>
  http.fileUpload("/v1/File/UploadFile", params);
