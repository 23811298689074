/*
 * @Author: 智客云网络科技
 * @Date: 2021-03-31 12:43:04 +0800
 * @LastEditors: 阡陌OvO
 * @LastEditTime: 2022-01-22 00:00:02
 * @Description: 工具类
 * @FilePath: \src\utils\index.js
 */

import Vue from "vue";
import watermark from "./watermark";
const _this = Vue.prototype;

//定义全局工具方法
const Utils = {
  /**
   * 从url中查询到指定参数值
   * @param {string} url url 不出入默认自动获取
   * @param {string} name 键名
   * @returns
   */
  urlgetval: (url = "", name) => {
    url = url || window.location.search.substring(1);
    var vars = url.split("&");
    for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split("=");
      if (pair[0] == name) {
        return pair[1];
      }
    }
    return undefined;
  },

  /**
   * 判断一个变量是否为null
   * @param {*} val
   * @returns 返回true或false
   */
  isNull: (val) => {
    return ["null", "undefined", "", null, undefined].indexOf(val) != -1;
  },

  // 将时间戳转化为指定时间
  // way: 方式 (1=年月日, 2=年月日时分秒) 默认1,  也可以指定格式 yyyy-MM-dd HH:mm:ss
  /**
   * 将时间戳转化为指定时间
   * @param {*} inputTime 时间
   * @param {*} way 方式(1=年月日, 2=年月日时分秒) 默认1 也可以指定格式 yyyy-MM-dd HH:mm:ss
   * @returns
   */
  forDate: (way, inputTime) => {
    if (_this.$utils.isNull(inputTime) == true) {
      inputTime = new Date();
    }
    var date = new Date(inputTime);
    var y = date.getFullYear();
    var m = date.getMonth() + 1;
    m = m < 10 ? "0" + m : m;
    var d = date.getDate();
    d = d < 10 ? "0" + d : d;
    var h = date.getHours();
    h = h < 10 ? "0" + h : h;
    var minute = date.getMinutes();
    var second = date.getSeconds();
    minute = minute < 10 ? "0" + minute : minute;
    second = second < 10 ? "0" + second : second;

    way = way || 1;
    // way == 1  年月日
    if (way === 1) {
      return y + "-" + m + "-" + d;
    }
    // way == 1  年月日时分秒
    if (way === 2) {
      return y + "-" + m + "-" + d + " " + h + ":" + minute + ":" + second;
    }
    // way == 具体格式   标准格式: yyyy-MM-dd HH:mm:ss
    if (typeof way == "string") {
      return way
        .replace("yyyy", y)
        .replace("MM", m)
        .replace("dd", d)
        .replace("HH", h)
        .replace("mm", minute)
        .replace("ss", second);
    }
    return y + "-" + m + "-" + d;
  },

  /**
   * 将时间转化为 多长时间以前 如: 3小时前
   * @param {*} d 时间
   * @returns
   */
  forDate2: (d) => {
    var hou = "前";

    if (d == null || d == "") {
      return "";
    }

    var d2 = new Date();
    var timestamp = new Date(d).getTime() - 1000;
    var mistiming = Math.round((d2 - timestamp) / 1000);
    if (mistiming < 0) {
      mistiming = 0 - mistiming;
      hou = "后";
    }
    var arrr = ["年", "月", "周", "天", "小时", "分钟", "秒"];
    var arrn = [31536000, 2592000, 604800, 86400, 3600, 60, 1];
    for (var i = 0; i < arrn.length; i++) {
      var inm = Math.floor(mistiming / arrn[i]);
      if (inm != 0) {
        return inm + arrr[i] + hou;
      }
    }
  },

  /**
   * 综合以上两种方式, 进行格式化 小于24小时的走forDate2, 否则forDat
   * @param {*} d
   * @param {*} way
   * @returns
   */
  forDate3: (d, way) => {
    if (d == null || d == "") {
      return "";
    }
    var cha = new Date().getTime() - new Date(d).getTime();
    cha = cha > 0 ? cha : 0 - cha;
    if (cha < 86400 * 1000) {
      return _this.$utils.forDate2(d);
    }
    return _this.$utils.forDate(way, d);
  },

  /**
   * 返回时间差, 此格式数组: [x, x, x, 天, 时, 分, 秒]
   * @param {datetime} small_time 开始时间
   * @param {datetime} big_time 结束时间
   * @returns
   */
  getSJC: (small_time, big_time) => {
    var date1 = new Date(small_time); //开始时间
    var date2 = new Date(big_time); //结束时间
    var date3 = date2.getTime() - date1.getTime(); //时间差秒
    //计算出相差天数
    var days = Math.floor(date3 / (24 * 3600 * 1000));

    //计算出小时数
    var leave1 = date3 % (24 * 3600 * 1000); //计算天数后剩余的毫秒数
    var hours = Math.floor(leave1 / (3600 * 1000));

    //计算相差分钟数
    var leave2 = leave1 % (3600 * 1000); //计算小时数后剩余的毫秒数
    var minutes = Math.floor(leave2 / (60 * 1000));

    //计算相差秒数
    var leave3 = leave2 % (60 * 1000); //计算分钟数后剩余的毫秒数
    var seconds = Math.round(leave3 / 1000);

    // 返回数组
    return [0, 0, 0, days, hours, minutes, seconds];
  },

  /**
   * 将日期, 加上指定天数
   * @param {*} d 日期
   * @param {*} n 天数
   * @returns
   */
  dateAdd: (d, n) => {
    var s = new Date(d).getTime();
    s += 86400000 * n;
    return new Date(s);
  },

  /**
   * json字符串转json对象 出错返回空json对象
   * @param {*} obj 待转换json
   * @returns
   */
  JSONParse: (obj) => {
    try {
      return JSON.parse(obj);
    } catch (e) {
      return {};
    }
  },

  /**
   * 截取指定长度字符 默认10
   * @param {string} str 字符串
   * @param {int} length 截断长度
   * @returns
   */
  maxLength: (str, length) => {
    length = length || 50;
    if (!str) {
      return "";
    }
    return str.length > length ? str.substr(0, length) + " ..." : str;
  },

  /**
   * 过滤掉标签
   * @param {string} str 字符串
   * @returns
   */
  filterTags: (str) => {
    if (!str) {
      return "";
    }
    return str.replace(/<[^>]+>/g, "");
  },

  /**
   * 为指定集合的每一项元素添加上is_update属性
   * @param {*} list
   * @returns
   */
  listAU: (list) => {
    list.forEach((ts) => {
      ts.is_update = false;
    });
    return list;
  },

  /**
   * 获得一段文字中所有图片的路径
   * @param {*} str
   * @returns
   */
  getSrcList: (str) => {
    try {
      var imgReg = /<img.*?(?:>|\/>)/gi; //匹配图片（g表示匹配所有结果i表示区分大小写）
      var srcReg = /src=[\\\\'\\\\"]?([^\\\\'\\\\"]*)[\\\\'\\\\"]?/i; //匹配src属性
      var arr = str.match(imgReg); // 图片数组
      var srcList = [];
      for (var i = 0; i < arr.length; i++) {
        var src = arr[i].match(srcReg);
        srcList.push(src[1]);
      }
      return srcList;
    } catch (e) {
      return [];
    }
  },

  /**
   * 无精度损失的乘法
   * @param {*} arg1
   * @param {*} arg2
   * @returns
   */
  accMul: (arg1, arg2) => {
    var m = 0,
      s1 = arg1.toString(),
      s2 = arg2.toString(),
      t;

    t = s1.split(".");
    // 判断有没有小数位，避免出错
    if (t[1]) {
      m += t[1].length;
    }

    t = s2.split(".");
    if (t[1]) {
      m += t[1].length;
    }

    return (
      (Number(s1.replace(".", "")) * Number(s2.replace(".", ""))) /
      Math.pow(10, m)
    );
  },

  /**
   * 正则验证是否为手机号
   * @param {*} str 手机号
   * @returns
   */
  isPhone: (str) => {
    str = str + "";
    if (/^1[34578]\d{9}$/.test(str)) {
      return true;
    }
    return false;
  },

  /**
   * 产生随机字符串
   * @param {*} len 长度 默认32
   * @returns
   */
  randomString: (len) => {
    len = len || 32;
    var $chars =
      "ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678"; /****默认去掉了容易混淆的字符oOLl,9gq,Vv,Uu,I1****/
    var maxPos = $chars.length;
    var str = "";
    for (var i = 0; i < len; i++) {
      str += $chars.charAt(Math.floor(Math.random() * maxPos));
    }
    return str;
  },

  /**
   * set cookie 值
   * @param {*} cname 名
   * @param {*} cvalue 值
   * @param {*} exdays 时间
   */
  setCookie: (cname, cvalue, exdays) => {
    exdays = exdays || 30;
    var d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    var expires = "expires=" + d.toGMTString();
    document.cookie =
      cname + "=" + escape(cvalue) + "; " + expires + "; path=/";
  },

  /**
   * get cookie 值
   * @param {*} objName cookie名
   * @returns
   */
  getCookie: (objName) => {
    var arrStr = document.cookie.split("; ");
    for (var i = 0; i < arrStr.length; i++) {
      var temp = arrStr[i].split("=");
      if (temp[0] == objName) {
        return unescape(temp[1]);
      }
    }
    return "";
  },

  /**
   * 复制指定文本
   * 注意 这个方法基于Clipboard API实现
   * 生产环境下需要HTTPS协议才能正常使用
   * @param {string} str 待复制文本
   */
  copyText: (str) => {
    //获取 Clipboard 对象
    const clipboardObj = navigator.clipboard;
    if (clipboardObj === undefined) {
      _this.$utils.msg("您当前的浏览器暂不支持一键复制！"); //输出提示
      return;
    }
    //执行写入操作
    clipboardObj.writeText(str);
  },

  /**
   * 将cookie序列化为k=v形式
   * @returns
   */
  strCookie: () => {
    return document.cookie.replace(/; /g, "&");
  },

  // 回到顶部
  goTop: () => {
    function smoothscroll() {
      var currentScroll =
        document.documentElement.scrollTop || document.body.scrollTop;
      if (currentScroll > 0) {
        window.requestAnimationFrame(smoothscroll);
        window.scrollTo(0, currentScroll - currentScroll / 5);
      }
    }
    smoothscroll();
  },

  /**
   * 去除json对象中的空值
   * @param {*} obj json对象
   * @returns
   */
  removeNull: (obj) => {
    var newObj = {};
    if (obj != undefined && obj != null) {
      for (var key in obj) {
        if (obj[key] === undefined || obj[key] === null || obj[key] == "") {
          //
        } else {
          newObj[key] = obj[key];
        }
      }
    }
    return newObj;
  },

  /**
   * JSON 浅拷贝, 返回拷贝后的obj
   * @param {*} obj
   * @returns
   */
  copyJSON: (obj) => {
    if (obj === null || obj === undefined) {
      return obj;
    }
    var new_obj = {};
    for (var key in obj) {
      new_obj[key] = obj[key];
    }
    return new_obj;
  },

  /**
   * json合并, 将 defaulet配置项 转移到 user配置项里 并返回 user配置项
   * @param {*} userOption
   * @param {*} defaultOption
   * @returns
   */
  extendJson: (userOption, defaultOption) => {
    if (!userOption) {
      return defaultOption;
    }
    for (var key in defaultOption) {
      if (userOption[key] === undefined) {
        userOption[key] = defaultOption[key];
      } else if (userOption[key] == null) {
        // console.log();
      } else if (typeof userOption[key] == "object") {
        _this.$utils.extendJson(userOption[key], defaultOption[key]); //深度匹配
      }
    }
    return userOption;
  },

  //解决layer拉伸或者最大化的时候，iframe高度不能自适应的问题
  solveLayerBug: (index) => {
    var selected = "#layui-layer" + index;
    var height = document.getElementById(selected).clientHeight();
    // var height = document.getElementById(selected).offsetHeight() //备选方案
    var title_height = document
      .getElementById(selected)
      .find(".layui-layer-title")
      .offsetHeight();
    document
      .getElementById(selected)
      .find("iframe")
      .setAttribute("style", "height:" + height - title_height + "px");
  },

  //调用接口执行全屏动作
  fullScreen: () => {
    if (document.documentElement.RequestFullScreen) {
      document.documentElement.RequestFullScreen();
    }
    //兼容火狐
    if (document.documentElement.mozRequestFullScreen) {
      document.documentElement.mozRequestFullScreen();
    }
    //兼容谷歌等可以webkitRequestFullScreen也可以webkitRequestFullscreen
    if (document.documentElement.webkitRequestFullScreen) {
      document.documentElement.webkitRequestFullScreen();
    }
    //兼容IE,只能写msRequestFullscreen
    if (document.documentElement.msRequestFullscreen) {
      document.documentElement.msRequestFullscreen();
    }
  },

  //调用接口执行退出全屏动作
  fullScreenNormal: () => {
    if (document.exitFullScreen) {
      document.exitFullscreen();
    }
    //兼容火狐
    if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    }
    //兼容谷歌等
    if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
    }
    //兼容IE
    if (document.msExitFullscreen) {
      document.msExitFullscreen();
    }
  },

  //返回一个字符串中是否有英文字母
  is_have_en: (str) => {
    var reg = /[a-z]/i;
    return reg.test(str); //true,说明有英文字母
  },

  /******************** 数组操作 ********************/

  /**
   * 从数组里获取数据,根据指定数据
   * @param {*} arr 数组
   * @param {*} prop 数据键名
   * @param {*} value 标定数据
   * @returns
   */
  arrayGet: (arr, prop, value) => {
    for (var i = 0; i < arr.length; i++) {
      if (arr[i][prop] == value) {
        return arr[i];
      }
    }
    return null;
  },

  /**
   * 根据指定数据从数组里获取index
   * @param {*} arr 数组
   * @param {*} prop 数据键名
   * @param {*} value 标定数据
   * @returns
   */
  arrayGetIndex: (arr, prop, value) => {
    for (var i = 0; i < arr.length; i++) {
      if (arr[i][prop] == value) {
        return i;
      }
    }
    return null;
  },

  /**
   * 从数组删除指定记录
   * @param {*} arr 数组
   * @param {*} item 记录值
   */
  arrayDelete: (arr, item) => {
    var index = arr.indexOf(item);
    if (index > -1) {
      arr.splice(index, 1);
    }
  },

  /**
   * 从数组删除指定id的记录
   * @param {*} arr 数组
   * @param {*} id 记录id
   */
  arrayDeleteById: (arr, id) => {
    var item = _this.$utils.arrayGet(arr, "id", id);
    _this.$utils.arrayDelete(arr, item);
  },

  /**
   * 将数组B添加到数组A的开头
   * @param {*} arrA
   * @param {*} arrB
   * @returns
   */
  unshiftArray: (arrA, arrB) => {
    if (arrB) {
      arrB.reverse().forEach(function (ts) {
        arrA.unshift(ts);
      });
    }
    return arrA;
  },

  /**
   * 将数组B添加到数组A的末尾
   * @param {*} arrA
   * @param {*} arrB
   * @returns
   */
  pushArray: (arrA, arrB) => {
    if (arrB) {
      arrB.forEach(function (ts) {
        arrA.push(ts);
      });
    }
    return arrA;
  },

  /******************** 弹窗提示 ********************/

  /**
   * 消息提示
   * @param {string} msg 消息文字
   * @param {object} type 主题 success / warning / info / error
   */
  msg: (msg = "操作成功", type = "info") => {
    _this.$message({
      type: type, //主题
      showClose: true, //是否显示关闭按钮
      offset: 20, //Message 距离窗口顶部的偏移量
      message: msg, //消息文字
    });
  },

  /**
   * 操作成功提示
   * @param {string} msg 提示文字
   */
  success: (msg = "操作成功!") => {
    _this.$utils.msg(msg, "success");
  },

  /**
   * 操作警告提示
   * @param {string} msg 提示文字
   */
  warn: (msg = "警告!") => {
    _this.$utils.msg(msg, "warning");
  },

  /**
   * 操作失败提示
   * @param {string} msg 提示文字
   */
  error: (msg = "操作失败!") => {
    _this.$utils.msg(msg, "error");
  },

  /**
   * 弹窗提示
   * @param {string} text 提示文字
   * @param {*} okFn 点击确定之后的回调函数
   */
  alert: (text, okFn) => {
    _this.$alert(text, "系统提示", {
      confirmButtonText: "确定",
      callback: () => {
        if (okFn) {
          okFn();
        }
      },
    });
  },

  /**
   * 确认框
   * @param {string} text 消息内容
   * @param {function} okFn 点击确定之后的回调函数
   * @param {string} type 消息类型 success / info / warning / error
   * @param {string} btn1 确定按钮的文本内容
   * @param {string} btn2 取消按钮的文本内容
   */
  confirm: (text, okFn, type = "warning", btn1 = "确认", btn2 = "取消") => {
    _this
      .$confirm(text, "系统提示", {
        confirmButtonText: btn1,
        cancelButtonText: btn2,
        type: type,
        dangerouslyUseHTMLString: true,
      })
      .then(() => {
        if (okFn) {
          okFn();
        }
      })
      .catch(() => {
        //什么都不做
      });
  },

  /**
   * 打开Loading
   * @param {string} msg 提示文字
   * @returns
   */
  loading: (msg = "努力加载中...", target = null) => {
    let loading = _this.$loading({
      text: msg,
      lock: true, //锁定滚动条
      background: "rgba(255, 255, 255, 0.8)",
      target: target || "body",
    }); // 打开全屏Loading
    return loading;
  },

  // 关闭Loading
  hideLoading: (loading = null) => {
    if (loading !== null) {
      loading.close();
    }
  },

  /**
   * 设置水印
   * @param {string} text 水印内容
   */
  setWatermark: (text1, text2) => {
    watermark.set(text1, text2);
  },
};

export default {
  install(Vue) {
    Vue.prototype.$utils = Utils;
  },
};
