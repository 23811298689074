/*
 * @Author: 智客云网络科技
 * @Date: 2022-02-12 20:29:13
 * @LastEditors: 阡陌OvO
 * @LastEditTime: 2022-02-12 20:30:15
 * @Description: 通过命令让dialog可以拖来拖去
 * @FilePath: \src\command\drag2anywhere\index.js
 */

const directive = (Vue) => {
  Vue.directive("drag2anywhere", {
    // 当被绑定的元素插入到 DOM 中时……
    inserted: (el) => {
      el.style.overflow = "hidden";
      const target = el.childNodes[0];
      const targetHeader = target.childNodes[0];
      let isOnclick = false;
      let mouseX;
      let mouseY;
      let objX;
      let objY;
      targetHeader.onmousedown = (event) => {
        isOnclick = true;
        target.style.cursor = "move";
        target.style.left = target.offsetLeft + "px"; // 先记录之前的位置
        target.style.top = target.offsetTop + "px"; // 先记录之前的位置
        target.style.margin = "0"; // 去掉margin
        mouseX = event.clientX;
        mouseY = event.clientY;
        objX = parseInt(target.style.left || target.offsetLeft);
        objY = parseInt(target.style.top || target.offsetTop);
        document.onmousemove = null; // 解绑监听鼠标拖动事件
        document.onmousemove = (event) => {
          // 监听鼠标拖动事件
          if (isOnclick) {
            event = event || window.event;
            target.style.left = parseInt(event.clientX - mouseX + objX) + "px";
            target.style.top = parseInt(event.clientY - mouseY + objY) + "px";
          }
        };
      };
      targetHeader.onmouseup = (event) => {
        event;
        // 松开鼠标
        isOnclick = false;
        target.style.cursor = "default";
      };
    },
  });
};
export default directive;
